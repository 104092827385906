import AuthService from '@/services/auth'

export const login = ({ commit, dispatch }, authData) => {
  return AuthService.login(authData)
    .then(({ data }) => {
      const { token, user } = data
      localStorage.setItem('token', token)
      localStorage.setItem('user', JSON.stringify(user))
      commit('SET_CURRENT_USER', { token, user })
    })
    .catch((err) => {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      const notification = {
        type: 'error',
        message: err.response.data.message || `Ошибка авторизации: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const logout = ({ commit }) => {
  commit('LOGOUT_USER')
  localStorage.removeItem('token')
  localStorage.removeItem('user')
}
