<template>
  <v-app>
    <router-view :key="$route.fullPath"></router-view>
    <NotificationContainer />
  </v-app>
</template>

<script>
import NotificationContainer from '@/components/notification/NotificationContainer'

export default {
  name: 'App',
  components: {
    NotificationContainer
  }
}
</script>
<style>
.v-data-table-header th {
  white-space: nowrap;
}
.v-text-field__details {
  margin-bottom: 0 !important;
  padding: 0 !important;
}
</style>
