import ProjectService from '@/services/ProjectService'

export const fetchProjects = ({ commit, dispatch }) => {
  commit('FETCH_START')
  return ProjectService.query()
    .then(({ data }) => {
      commit('SET_PROJECTS', data.projects)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
    })
}

export const createProject = ({ dispatch }, project) => {
  return ProjectService.post(project)
    .then(() => {
      dispatch('fetchProjects')
      const notification = {
        type: 'success',
        message: 'Проект успешно добавлен!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при добавлении проекта: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}
