import api from './api'

export default {
  query(projectId) {
    return api.get(`/projects/${projectId}/kits`)
  },
  post(projectId, kit) {
    return api.post(`/projects/${projectId}/kits`, kit)
  },
  get(kitId) {
    return api.get(`/kits/${kitId}`)
  },
  update(kit) {
    return api.put(`/kits/${kit.id}`, kit)
  },
  delete(id) {
    return api.delete(`/kits/${id}`)
  }
}
