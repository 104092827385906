export const roles = (state) => {
  return state.roles
}

export const getRoleById = (state) => (id) => {
  return state.roles.find((role) => role.id === id)
}

export const isLoading = (state) => {
  return state.isLoading
}
