let nextId = 1

export const PUSH_NOTIFICATION = (state, notification) => {
  state.notifications.push({
    ...notification,
    id: nextId++
  })
}

export const REMOVE_NOTIFICATION = (state, notification) => {
  state.notifications = state.notifications.filter((item) => {
    return item.id != notification.id
  })
}
