export const products = (state) => {
  return state.products
}
export const productPictures = (state) => {
  return state.productPictures
}

export const productPicture = (state) => {
  return state.productPicture
}
