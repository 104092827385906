import api from './api'

export default {
  getSettings(project) {
    return api.get(`/project/${project}/settings/get`)
  },
  addSettings(settings) {
    return api.post(`/settings/`, settings)
  },
  updateSettings(settings) {
    return api.put(`/settings/`, settings)
  }
}
