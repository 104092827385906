export const horseRidesCount = (state) => {
  return state.horseRidesCount.length
}

export const horseRides = (state) => {
  return state.horseRides
}

export const isLoading = (state) => {
  return state.isLoading
}
