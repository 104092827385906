import ProductService from '@/services/ProductService'

export const fetchProducts = ({ commit, dispatch }, { projectId }) => {
  return ProductService.query(projectId)
    .then(({ data }) => {
      commit('SET_PRODUCTS', data.products)
    })
    .catch((err) => {
      const notification = makeErrNotification(err, `Возникла ошибка при получении данных: `)
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const createProduct = ({ dispatch }, { product, projectId }) => {
  return ProductService.post(product, projectId)
    .then(() => {
      dispatch('fetchProducts', { projectId: projectId })
      const notification = {
        type: 'success',
        message: 'Продукт создан!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = makeErrNotification(err, `Возникла ошибка при создании продукта: `)
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}
export const resetProductPicture = ({ commit, dispatch }) => {
  commit('RESET_PRODUCT_PICTURE')
  const notification = {
    type: 'success',
    message: `Изображение сброшено`
  }
  dispatch('notification/add', notification, { root: true })
}

export const updateProduct = ({ dispatch }, { product, projectId }) => {
  return ProductService.put(product)
    .then(() => {
      dispatch('fetchProducts', { projectId: projectId })
      const notification = {
        type: 'success',
        message: 'Продукт изменён!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при изменении продукта: ${err.response.data.message || err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const uploadProductPicture = ({ commit, dispatch }, formData) => {
  return ProductService.upload(formData)
    .then((res) => {
      commit('SET_PRODUCT_PICTURE', res.data)
      const notification = {
        type: 'success',
        message: `Изображение загружено`
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при обработке данных: ${err.response.data.message || err}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

function makeErrNotification(err, message) {
  let errMsg = `Неизвестная ошибка`
  if (err.response && err.response.data && err.response.data.message) {
    errMsg = err.response.data.message
  } else if (err.message) {
    errMsg = err.message
  }
  return {
    type: 'error',
    message: message + errMsg
  }
}
