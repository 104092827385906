import api from './api'

export default {
  query(projectId) {
    return api.get(`projects/${projectId}/products/`)
  },
  post(product, projectId) {
    return api.post(`projects/${projectId}/products/`, product)
  },
  put(product) {
    return api.put(`/products/`, product)
  },
  upload(formData) {
    return api.post(`/products/picture`, formData)
  }
}
