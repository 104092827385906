import ProjectService from '@/services/ProjectService'
import KitService from '@/services/KitService'
import PhotoCoupeService from '@/services/PhotoCoupeService'
import SaleService from '@/services/SaleService'
import PromotionService from '../../../services/PromotionService'

export const fetchProject = ({ commit, dispatch }, projectId) => {
  return ProjectService.get(projectId)
    .then(({ data }) => {
      commit('SET_PROJECT', data.project)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
    })
}

export const fetchKits = ({ commit, dispatch }, projectId) => {
  return KitService.query(projectId)
    .then(({ data }) => {
      commit('SET_KITS', data.kits)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const createKit = ({ dispatch }, { projectId, kit }) => {
  return KitService.post(projectId, kit)
    .then(() => {
      dispatch('fetchKits', projectId)
      const notification = {
        type: 'success',
        message: 'Комплект успешно добавлен!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при добавлении комплекта: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const updateKit = ({ dispatch }, { projectId, kit }) => {
  return KitService.update(kit)
    .then(() => {
      dispatch('fetchKits', projectId)
      const notification = {
        type: 'success',
        message: 'Комплект успешно изменен!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при изменении комплекта: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const deleteKit = ({ dispatch }, { projectId, kitId }) => {
  return KitService.delete(kitId)
    .then(() => {
      dispatch('fetchKits', projectId)
      const notification = {
        type: 'success',
        message: 'Комплект успешно удален!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при удалении комплекта: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const fetchPhotoCoupes = ({ commit, dispatch }, projectId) => {
  commit('FETCH_START')
  return PhotoCoupeService.query(projectId)
    .then(({ data }) => {
      commit('SET_PHOTO_COUPES', data.photoCoupes)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const uploadPhotoCoupes = ({ dispatch }, { projectId, formData }) => {
  return PhotoCoupeService.upload(projectId, formData)
    .then((res) => {
      dispatch('fetchPhotoCoupes', projectId)
      const notification = {
        type: 'success',
        message: `Добавлено ${res.data.photoCoupes.length} записей!`
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при обработке данных: ${err.response.data.message || err}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const updatePhotoCoupe = ({ dispatch }, { projectId, photoCoupe }) => {
  return PhotoCoupeService.update(photoCoupe)
    .then(() => {
      dispatch('fetchPhotoCoupes', projectId)
      const notification = {
        type: 'success',
        message: 'Данные изменены!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при изменении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const fetchSales = ({ commit, dispatch }, { projectId, searchQuery }) => {
  commit('FETCH_START')
  return SaleService.query(projectId, searchQuery)
    .then(({ data }) => {
      commit('SET_SALES', data.sales)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const fetchSalesByDate = ({ commit, dispatch }, { projectId, queryParams }) => {
  commit('FETCH_START')
  return SaleService.queryDate(projectId, queryParams)
    .then(({ data }) => {
      commit('SET_SALES', data.sales)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const fetchSalesStatistics = ({ commit, dispatch }, { projectId, queryParams }) => {
  commit('FETCH_START')
  return SaleService.querySalesStatistics(projectId, queryParams)
    .then(({ data }) => {
      commit('SET_SALES_STATISTICS', data.statistic)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const fetchPromotions = ({ commit, dispatch }, projectId) => {
  commit('FETCH_START')
  return PromotionService.query(projectId)
    .then(({ data }) => {
      commit('SET_PROMOTIONS', data.promotions)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}
