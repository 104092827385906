import KitService from '@/services/KitService'
import StatusService from '@/services/StatusService'
import TaskService from '@/services/TaskService'

export const fetchKit = ({ commit, dispatch }, kitId) => {
  return KitService.get(kitId)
    .then(({ data }) => {
      commit('SET_KIT', data.kit)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
    })
}

export const fetchStatuses = ({ commit, dispatch }, kitId) => {
  return StatusService.query(kitId)
    .then(({ data }) => {
      commit('SET_STATUSES', data.statuses)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const fetchTasks = ({ commit, dispatch }, kitId) => {
  return TaskService.query(kitId)
    .then(({ data }) => {
      commit('SET_TASKS', data.tasks)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const createTask = ({ dispatch }, { kitId, taskData }) => {
  return TaskService.post(kitId, taskData)
    .then(() => {
      dispatch('fetchTasks', kitId)
      const notification = {
        type: 'success',
        message: 'Задача создана!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при создании задачи: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}
