import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import dashboard from './modules/dashboard'
import hardware from './modules/hardware'
import horseRide from './modules/horseRide'
import kit from './modules/kit'
import notification from './modules/notification'
import project from './modules/project'
import product from './modules/product'
import service from './modules/service'
import remain from './modules/remain'
import role from './modules/role'
import settings from './modules/settings'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    dashboard,
    hardware,
    horseRide,
    kit,
    notification,
    project,
    product,
    service,
    role,
    remain,
    settings,
    user
  }
})
