export const FETCH_START = (state) => {
  state.isLoading = true
}

export const SET_PROJECT = (state, project) => {
  state.project = project
}

export const SET_KITS = (state, { rows }) => {
  state.kits = rows
}

export const SET_PHOTO_COUPES = (state, { rows }) => {
  state.photoCoupes = rows
  state.isLoading = false
}

export const SET_SALES = (state, { rows }) => {
  state.sales = rows
}

export const SET_SALES_STATISTICS = (state, salesStatistics) => {
  state.salesStatistics = salesStatistics
  state.isLoading = false
}

export const SET_PROMOTIONS = (state, { rows }) => {
  state.promotions = rows
  state.isLoading = false
}
