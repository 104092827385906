export const project = (state) => {
  return state.project
}

export const kits = (state) => {
  return state.kits
}

export const photoCoupes = (state) => {
  return state.photoCoupes
}

export const sales = (state) => {
  return state.sales
}

export const salesStatistics = (state) => {
  return state.salesStatistics
}

export const promotions = (state) => {
  return state.promotions
}

export const isLoading = (state) => {
  return state.isLoading
}
