import ServiceService from '@/services/ServiceService'

export const fetch = ({ commit, dispatch }, { projectId }) => {
  return ServiceService.query(projectId)
    .then(({ data }) => {
      commit('SET_SERVICES', data.services)
    })
    .catch((err) => {
      const notification = makeErrNotification(err, `Возникла ошибка при получении данных: `)
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const create = ({ dispatch }, { product, projectId }) => {
  return ServiceService.post(product, projectId)
    .then(() => {
      dispatch('fetch', { projectId: projectId })
      const notification = {
        type: 'success',
        message: 'Услуга создана!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = makeErrNotification(err, `Возникла ошибка при создании продукта: `)
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}
export const resetProductPicture = ({ commit, dispatch }) => {
  commit('RESET_PRODUCT_PICTURE')
  const notification = {
    type: 'success',
    message: `Изображение сброшено`
  }
  dispatch('notification/add', notification, { root: true })
}

export const updateService = ({ dispatch }, { service, projectId }) => {
  return ServiceService.put(service)
    .then(() => {
      dispatch('fetch', { projectId: projectId })
      const notification = {
        type: 'success',
        message: 'Услуга успешно изменена'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при изменении услуги: ${err.response.data.message || err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const uploadProductPicture = ({ commit, dispatch }, formData) => {
  return ServiceService.upload(formData)
    .then((res) => {
      commit('SET_PRODUCT_PICTURE', res.data)
      const notification = {
        type: 'success',
        message: `Изображение загружено`
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при обработке данных: ${err.response.data.message || err}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

function makeErrNotification(err, message) {
  let errMsg = `Неизвестная ошибка`
  if (err.response && err.response.data && err.response.data.message) {
    errMsg = err.response.data.message
  } else if (err.message) {
    errMsg = err.message
  }
  return {
    type: 'error',
    message: message + errMsg
  }
}
