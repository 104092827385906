import RemainService from '@/services/RemainService'

export const fetchRemains = ({ commit, dispatch }, { projectId }) => {
  return RemainService.getAllRemains(projectId)
    .then(({ data }) => {
      commit('FETCH_REMAINS_START')
      commit('SET_REMAINS', { remains: data.remains })
    })
    .catch((err) => {
      const notification = makeErrNotification(err, `Возникла ошибка при получении остатков: `)
      commit('FETCH_REMAINS_ERROR', { error: notification.message })
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}
function makeErrNotification(err, message) {
  let errMsg = `Неизвестная ошибка`
  if (err.response && err.response.data && err.response.data.message) {
    errMsg = err.response.data.message
  } else if (err.message) {
    errMsg = err.message
  }
  return {
    type: 'error',
    message: message + errMsg
  }
}
