import api from './api'

export default {
  query(projectId, searchQuery) {
    return api.get(`projects/${projectId}/sales?${searchQuery}`)
  },
  queryDate(projectId, queryParams) {
    return api.get(`projects/${projectId}/sales/dateFilter`, {
      params: queryParams
    })
  },
  queryReport(projectId, queryParams) {
    return api.get(`projects/${projectId}/sales/download`, {
      params: queryParams,
      responseType: 'blob'
    })
  },
  querySalesStatistics(projectId, queryParams) {
    return api.get(`projects/${projectId}/sales/statisticAll`, {
      params: queryParams
    })
  }
}
