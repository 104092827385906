import HorseRideService from '@/services/HorseRideService'

export const fetchHorseRides = ({ commit, dispatch }) => {
  commit('FETCH_START')
  return HorseRideService.query()
    .then(({ data }) => {
      commit('SET_HORSE_RIDES', data.horseRides)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
    })
}

export const upload = ({ dispatch }, formData) => {
  return HorseRideService.upload(formData)
    .then((res) => {
      dispatch('fetchHorseRides')
      const notification = {
        type: 'success',
        message: `Добавлено ${res.data.horseRides.length} записей!`
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при обработке данных: ${err.response.data.message || err}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const updateHorseRide = ({ dispatch }, horseRide) => {
  return HorseRideService.update(horseRide)
    .then(() => {
      dispatch('fetchHorseRides')
      const notification = {
        type: 'success',
        message: 'Данные изменены!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при изменении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}
