export const FETCH_START = (state) => {
  state.isLoading = true
}

export const SET_STATUSES = (state, { count, rows }) => {
  state.statuses = rows
  state.statusesCount = count
  state.isLoading = false
}

export const SET_TASKS = (state, { count, rows }) => {
  state.tasks = rows
  state.tasksCount = count
  state.isLoading = false
}
