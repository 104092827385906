import api from './api'

export default {
  query(kitId) {
    return api.get(`/kits/${kitId}/tasks`)
  },
  post(kitId, task) {
    return api.post(`/kits/${kitId}/tasks`, task)
  }
}
