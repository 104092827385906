import api from './api'

export default {
  query() {
    return api.get(`/horse-rides`)
  },
  upload(formData) {
    return api.post(`/horse-rides`, formData)
  },
  update(horseRide) {
    return api.put(`/horse-rides/${horseRide.id}`, horseRide)
  }
}
