export const projectCount = (state) => {
  return state.projects.length
}

export const projects = (state) => {
  return state.projects
}

export const isLoading = (state) => {
  return state.isLoading
}
