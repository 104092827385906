import api from './api'

export default {
  query(projectId) {
    return api.get(`/projects/${projectId}/photo-coupes`)
  },
  upload(projectId, formData) {
    return api.post(`/projects/${projectId}/photo-coupes`, formData)
  },
  update(photoCoupe) {
    return api.put(`/photo-coupes/${photoCoupe.id}`, photoCoupe)
  }
}
