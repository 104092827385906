export const FETCH_START = (state) => {
  state.isLoading = true
}

export const SET_PRODUCTS = (state, { count, rows }) => {
  state.products = rows
  state.productsCount = count
  state.isLoading = false
}
export const SET_PRODUCT_PICTURES = (state, { count, rows }) => {
  state.productPictures = rows
  state.productPicturesCount = count
  state.productPicturesIsLoading = false
}
export const SET_PRODUCT_PICTURE = (state, { picture }) => {
  if (picture) {
    state.productPicture = picture
  } else {
    state.productPicture = null
  }
}

export const RESET_PRODUCT_PICTURE = (state) => {
  state.productPicture = null
}
