import HardwareService from '@/services/HardwareService'

export const fetchPageHardware = ({ commit, dispatch, state }, { page }) => {
  return HardwareService.getPageHardware(state.perPage, page)
    .then(({ data }) => {
      commit('SET_HARDWARES_COUNT', parseInt(data.count))
      commit('SET_HARDWARES', data.rows)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: 'There was a problem fetching hardwares: ' + err.message
      }
      dispatch('notification/add', notification, { root: true })
    })
}

export const fetchHardware = ({ commit, getters, state }, id) => {
  if (id == state.hardware.id) {
    return state.hardware
  }

  var hardware = getters.getHardwareById(id)
  if (hardware) {
    commit('SET_HARDWARE', hardware)
    return hardware
  } else {
    return HardwareService.getHardware(id).then(({ data }) => {
      commit('SET_HARDWARE', data)
      return data
    })
  }
}

export const createHardware = ({ commit, dispatch }, hardware) => {
  return HardwareService.postHardware(hardware)
    .then(({ data }) => {
      commit('ADD_HARDWARE', data)
      commit('SET_HARDWARE', data)
      const notification = {
        type: 'success',
        message: 'Your hardware has been created!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((error) => {
      const notification = {
        type: 'error',
        message: 'There was a problem creating your hardware: ' + error.message
      }
      dispatch('notification/add', notification, { root: true })
      throw error
    })
}
