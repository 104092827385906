import UserService from '@/services/UserService'

export const fetchUsers = ({ commit, dispatch }) => {
  commit('FETCH_START')
  return UserService.query()
    .then(({ data }) => {
      commit('SET_USERS', data.users)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

export const createUser = ({ dispatch }, user) => {
  return UserService.post(user)
    .then(() => {
      dispatch('fetchUsers')
      const notification = {
        type: 'success',
        message: 'Пользователь успешно добавлен!'
      }
      dispatch('notification/add', notification, { root: true })
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при добавлении пользователя: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}
