import RoleService from '@/services/RoleService'

export const fetchRoles = ({ commit, dispatch }) => {
  commit('FETCH_START')
  return RoleService.query()
    .then(({ data }) => {
      commit('SET_ROLES', data.roles)
    })
    .catch((err) => {
      const notification = {
        type: 'error',
        message: `Возникла ошибка при получении данных: ${err.message}`
      }
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}
