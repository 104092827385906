import SettingsService from '@/services/SettingsService'

export const getSettings = ({ commit, dispatch }, project) => {
  commit('FETCH_SETTINGS_START')
  return SettingsService.getSettings(project)
    .then(({ data }) => {
      commit('SET_SETTINGS', data.settings)
      commit('FETCH_SETTINGS_END')
    })
    .catch((err) => {
      const notification = makeErrNotification(err, `Возникла ошибка при получении настроек: `)
      commit('FETCH_SETTINGS_ERROR', { error: notification.message })
      dispatch('notification/add', notification, { root: true })
      throw err
    })
}

function makeErrNotification(err, message) {
  let errMsg = `Неизвестная ошибка`
  if (err.response && err.response.data && err.response.data.message) {
    errMsg = err.response.data.message
  } else if (err.message) {
    errMsg = err.message
  }
  return {
    type: 'error',
    message: message + errMsg
  }
}
