import api from './api'

export default {
  query() {
    return api.get('/users')
  },
  post(user) {
    return api.post('/users', user)
  }
}
