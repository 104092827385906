export const hardwaresCount = (state) => {
  return state.hardwaresCount
}

export const hardwares = (state) => {
  return state.hardwares
}

export const hardware = (state) => {
  return state.hardware
}

export const getHardwareById = (state) => (id) => {
  return state.hardwares.find((hardware) => hardware.id === id)
}
