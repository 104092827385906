export const SET_HARDWARES_COUNT = (state, hardwaresCount) => {
  state.hardwaresCount = hardwaresCount
}

export const SET_HARDWARES = (state, hardwares) => {
  state.hardwares = hardwares
}

export const SET_HARDWARE = (state, hardware) => {
  state.hardware = hardware
}

export const ADD_HARDWARE = (state, hardware) => {
  state.hardwares.push(hardware)
}
