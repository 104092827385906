import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

function loadView(viewName) {
  return () => import(`@/views/${viewName}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: loadView('Home'),
    redirect: { name: 'projects' },
    children: [
      {
        path: 'project',
        name: 'projects',
        component: loadView('Projects')
      },
      {
        path: 'project/:projectId',
        name: 'project',
        component: loadView('Project')
      },
      {
        path: 'project/:projectId/kit',
        name: 'project-kits',
        component: loadView('ProjectKits'),
        props: true
      },
      {
        path: 'project/:projectId/photo-coupe',
        name: 'project-photo-coupes',
        component: loadView('ProjectPhotoCoupes')
      },
      {
        path: 'project/:projectId/horse-ride',
        name: 'horse-rides',
        component: loadView('HorseRides')
      },
      {
        path: 'project/:projectId/sale',
        name: 'project-sales',
        component: loadView('ProjectSales')
      },
      {
        path: 'project/:projectId/statistic',
        name: 'project-statistic',
        component: loadView('Statistic')
      },
      {
        path: 'project/:projectId/product',
        name: 'products',
        component: loadView('Products')
      },
      {
        path: 'project/:projectId/service',
        name: 'services',
        component: loadView('Services')
      },
      {
        path: 'project/:projectId/remain',
        name: 'remains',
        component: loadView('Remains')
      },
      {
        path: '/kit/:kitId/status',
        name: 'kit-status',
        component: loadView('KitStatus'),
        props: true
      },
      {
        path: '/kit/:kitId/task',
        name: 'kit-tasks',
        component: loadView('KitTasks'),
        props: true
      },
      { path: '/user', name: 'users', component: loadView('Users') }
    ]
  },
  {
    path: '/project/:projectId/statistic',
    name: 'project-statistic-no-nav',
    component: loadView('Statistic')
  },
  {
    path: '/project/:projectId/stats',
    name: 'automat-ruskeala-sales-stats',
    component: loadView('AutomatRuskealaSalesStats')
  },
  {
    path: '/project/:projectId/stats',
    name: 'automat-sochi-sales-stats',
    component: loadView('AutomatSochiSalesStats')
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('UserLogin')
  },
  {
    path: '/404',
    component: loadView('NotFound'),
    name: '404',
    props: true
  },
  {
    path: '/network-issue',
    component: loadView('NetworkIssue'),
    name: 'network-issue'
  },
  {
    path: '*',
    redirect: { name: '404', params: { resource: 'page' } }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['login']
  const authRequired = !publicPages.includes(to.name)
  const loggedIn = store.getters['auth/isLoggedIn']
  const user = store.getters['auth/user']

  if (authRequired && !loggedIn) {
    next({ name: 'login' })
  } else if (
    loggedIn &&
    user &&
    (user.id === 6 || user.id === 15 || user.id === 25) &&
    to.name !== 'project-statistic-no-nav' &&
    to.name !== 'automat-ruskeala-sales-stats'
  ) {
    next({ name: 'automat-ruskeala-sales-stats', params: { projectId: 1 } })
  } else if (loggedIn && user && (user.id === 8 || user.id === 13) && to.name !== 'project-statistic-no-nav' && to.name !== 'automat-sochi-sales-stats') {
    next({ name: 'automat-sochi-sales-stats', params: { projectId: 4 } })
  } else if (to.name === 'login' && loggedIn) {
    next({ name: 'home' })
  } else {
    next()
  }
})
export default router
