import api from './api'

export default {
  getPageHardware() {
    return api.get(`/hardware`)
  },
  getHardware(id) {
    return api.get(`/hardware/${id}`)
  },
  postHardware(hardware) {
    return api.post(`/hardware`, hardware)
  }
}
