import api from './api'

export default {
  query() {
    return api.get(`/projects`)
  },
  get(id) {
    return api.get(`/projects/${id}`)
  },
  post(project) {
    return api.post('/projects', project)
  }
}
