export const kit = (state) => {
  return state.project
}

export const statuses = (state) => {
  return state.statuses
}

export const tasks = (state) => {
  return state.tasks
}
