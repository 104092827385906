export const FETCH_REMAINS_START = (state) => {
  state.isRemainsLoading = true
  state.isRemainsError = false
}

export const SET_REMAINS = (state, { remains }) => {
  state.remains = remains
  state.isRemainsLoading = false
  state.isRemainsError = false
}

export const FETCH_REMAINS_ERROR = (state, { error }) => {
  state.isRemainsLoading = false
  state.isRemainsError = true
  state.remainsError = error
}
