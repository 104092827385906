export const FETCH_SETTINGS_START = (state) => {
  state.isSettingsLoading = true
  state.isSettingsError = false
}
export const FETCH_SETTINGS_END = (state) => {
  state.isSettingsLoading = false
  state.isSettingsError = false
}
export const FETCH_SETTINGS_ERROR = (state, { error }) => {
  state.isSettingsLoading = false
  state.isSettingsError = true
  state.getSettingsError = error
}

export const SET_SETTINGS = (state, { settings }) => {
  state.settings = settings
}
