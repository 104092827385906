//DEV, TEST, BATTLE, LOCAL
const UPLOAD_MODE = ''

const DEV_IP = `http://172.30.8.34`
const TEST_IP = `http://172.30.8.154`
const BATTLE_IP = `https://monitor.tsi.ru`
const LOCAL_IP = `http://localhost:3000`

let baseURL = '/api/v1'
let anotherAPI = false
let pictureBaseUrl = '../../'

if (UPLOAD_MODE === 'DEV') {
  pictureBaseUrl = DEV_IP + '/'
  baseURL = DEV_IP + baseURL
  anotherAPI = true
} else if (UPLOAD_MODE === 'TEST') {
  pictureBaseUrl = TEST_IP + '/'
  baseURL = TEST_IP + baseURL
  anotherAPI = true
} else if (UPLOAD_MODE === 'BATTLE') {
  pictureBaseUrl = BATTLE_IP + '/'
  baseURL = BATTLE_IP + baseURL
  anotherAPI = true
} else if (UPLOAD_MODE === 'LOCAL') {
  pictureBaseUrl = LOCAL_IP + '/'
  baseURL = LOCAL_IP + baseURL
  anotherAPI = true
}

export default {
  baseURL: baseURL,
  pictureBaseUrl: pictureBaseUrl,
  anotherAPI: anotherAPI
}
